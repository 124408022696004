import { mapGetters } from "vuex";

export const permissionMixin = {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  created: function() {},
  methods: {
    /**
     * Check if logged user has at least 1 permission included in the array of parameter permissions
     * @param {Array} permissions
     * @returns {boolean}
     */
    checkIfUserHasPermission: function(permissions) {
      let userPermissions = [];
      let hasPermission = false;
      if (this.currentUser){
        userPermissions = this.currentUser.permissions;
        if (
          !permissions ||
          (permissions &&
            permissions.length &&
            permissions.some(v => userPermissions.includes(v)))
        ) {
          hasPermission = true;
        }
      }
      return hasPermission;
    },
    /**
     * Check if logged user has role included in the array of parameter roles
     * @param {Array} roles
     * @returns {boolean}
     */
    checkIfUserHasRole: function(roles) {
      let userRole;
      let hasRole = false;

      if (this.currentUser) {
        userRole = this.currentUser.role;
        if (!roles || (roles && roles.length && roles.includes(userRole))) {
          hasRole = true;
        }
      }
      return hasRole;
    }
  }
};
